import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

function ContactForm() {
  const { t } = useTranslation();
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    telephone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Client-side validation before submission
    const { name, email, telephone, subject, message } = formData;

    if (!name || !email || !telephone || !subject || !message) {
      toast.error("Please fill out all fields before submitting.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    emailjs
      .sendForm("service_tvhbqir", "template_lja3ca5", form.current, {
        publicKey: "E3GQ7cvXwuiGXs0nP",
      })
      .then((result) => {
        console.log("Email sent successfully:", result.text);
        toast.success("Email sent successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        // Reset the form after successful submission
        setFormData({
          name: "",
          email: "",
          telephone: "",
          subject: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
      });
  };

  return (
    <div className="contact-card">
      <div className="contact-card-heading">
        <h1>{t("get_in_touch")}</h1>
        <p className="my-1">{t("contact_help_text")}</p>
      </div>
      <form ref={form} onSubmit={handleSubmit}>
        <Box
          component="div"
          sx={{
            "& > :not(style)": { m: 1 },
          }}
          autoComplete="off"
        >
          <TextField
            name="name"
            label={t("name")}
            value={formData.name}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            type="email"
            name="email"
            label={t("email")}
            value={formData.email}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            type="number"
            name="telephone"
            label={t("telephone")}
            value={formData.telephone}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            name="subject"
            label={t("subject")}
            value={formData.subject}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            name="message"
            label={t("message")}
            value={formData.message}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
          />
        </Box>
        <button>{t("submit")}</button>
      </form>
    </div>
  );
}

export default ContactForm;
