import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArounderImg1 from "../Assets/company-name.png";
import ArounderImg2 from "../Assets/company-email.png";
import ArounderImg3 from "../Assets/iban-cert.png";
import ArounderImg4 from "../Assets/national-id.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

const Step2 = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({ ...formData, [name]: checked });
  };

  return (
    <div>
      <h2> {t("instructions")} </h2>
      <p className="text-light my-1">{t("instructions_text1")}</p>
      <p>{t("instructions_text2")}</p>
      <div>
        <Slider {...settings}>
          <div className="arounder-carousel-card">
            <img src={ArounderImg1} alt="" width={100} />
            <h3>{t("arounder_reg_title1")}</h3>
            <p>{t("arounder_reg_text1")}</p>
          </div>
          <div className="arounder-carousel-card">
            <img src={ArounderImg2} alt="" width={100} />
            <h3>{t("arounder_reg_title2")}</h3>
            <p>{t("arounder_reg_text2")}</p>
          </div>
          <div className="arounder-carousel-card">
            <img src={ArounderImg3} alt="" width={100} />
            <h3>{t("arounder_reg_title3")}</h3>
            <p>{t("arounder_reg_text3")}</p>
          </div>
          <div className="arounder-carousel-card">
            <img src={ArounderImg4} alt="" width={100} />
            <h3>{t("arounder_reg_title4")}</h3>
            <p>{t("arounder_reg_text4")}</p>
          </div>
        </Slider>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <FormGroup>
          <a
            href="/policy"
            target="_blank"
            style={{ color: "#000", textDecoration: "underline" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.understandInstructions || false}
                  onChange={handleCheckboxChange}
                  name="understandInstructions"
                />
              }
            />
            {t("accept_instructions")}
          </a>

          <a
            href="/terms"
            target="_blank"
            style={{ color: "#000", textDecoration: "underline" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.acceptTerms || false}
                  onChange={handleCheckboxChange}
                  name="acceptTerms"
                />
              }
            />
            {t("accept_terms")}
          </a>
        </FormGroup>
      </div>
    </div>
  );
};

export default Step2;
