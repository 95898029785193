import React, { useState, useEffect, useMemo } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

const Step1 = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState([]);

  const place = formData.country;
  const region = useMemo(
    () =>
      country
        .filter((item) => (item.country_name) === place && item.status.toLowerCase() === "active")
        .map((item) => item.name + " - " + item.name_ar),
    [country, place]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        "https://us-central1-aroundu-app.cloudfunctions.net/v1public/cache/places/all"
      );
      const data = await response.json();
      setCountry(data.data);
    }
    fetchData();
  }, []);

  return (
    <div>
      <h2 style={{ marginBottom: "1rem" }}>{t("add_city")}</h2>
      <FormControl fullWidth>
        <InputLabel id="country-select-label">
          {t("select_country")} *
        </InputLabel>
        <Select
          labelId="country-select-label"
          name="country"
          required
          value={formData.country}
          label={t("select_country")}
          onChange={handleChange}
        >
          {Array.from(new Set(country.map((item) => (item.country_name + ' - ' + item.country_name_ar)))).map(
            (countryName) => (
              <MenuItem key={countryName} value={countryName.split("-")[0].trim()}>
                {countryName}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      <br />
      <br />
      <FormControl fullWidth>
        <InputLabel id="region-select-label">{t("select_city")} *</InputLabel>
        <Select
          labelId="region-select-label"
          name="region"
          required
          value={formData.region}
          label={t("select_city")}
          onChange={handleChange}
        >
          {region.map((item) => (
            <MenuItem key={item} value={item.split("-")[0].trim()}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Step1;
