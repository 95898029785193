import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import { useTranslation } from "react-i18next";
import DocImg from "../Assets/paper.png";

const Step6 = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Validate National ID to ensure it's exactly 10 digits
    if (name === "national_id") {
    if (!/^\d{0,10}$/.test(value)) {
      alert("National ID must be a 10-digit number.");
      return;
    }
  }};

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    console.log(files);
    setFormData({ ...formData, [name]: files[0] });
  };

  const [banks, setBanks] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        "https://us-central1-aroundu-app.cloudfunctions.net/v1public/cache/banks/all"
      );
      const data = await response.json();
      setBanks(data.data);
    }
    fetchData();
  }, []);

  return (
    <div>
      <div className="arounder-carousel-card">
        <img src={DocImg} alt="" width={100} />
        <h2>{t("doc_req")}</h2>
        <p className="text-light my-1">{t("doc_req_text")}</p>
      </div>
      {/* <h2>{t("doc_req")}</h2>
      <p className="text-light my-1">{t("doc_req_text")}</p> */}
      <FormControl fullWidth style={{ marginBottom: "1rem" }}>
        <InputLabel id="demo-simple-select-label">
          {t("select_bank")}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formData.bank_id}
          label={t("select_bank")}
          name="bank_id"
          onChange={handleChange}
        >
          {banks.map((item) => (
            <MenuItem key={item.id} value={item.name.split('-')[0].trim()}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* <label>
        {t("iban_no")}:
        <input
          type="text"
          name="iban"
          value={formData.iban}
          onChange={handleChange}
        />
        <input type="file" name="iban_image" onChange={handleFileChange} />
      </label> */}
      <TextField
        name="iban"
        label={t("iban_no")}
        value={formData.iban}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        required
      />
      <br />
      <Input type="file" name="iban_image" onChange={handleFileChange} />
      <br />
      {/* <label htmlFor="IBAN Certificate">
        <Button
          variant="contained"
          component="span"
          startIcon={<CloudUploadIcon />}
          onChange={handleFileChange}
        >
          Upload IBAN Image
        </Button>
        <Typography variant="body2" component="span" sx={{ my: 1 }}>
          Document uploaded successfully
        </Typography>
      </label> */}
      {/* <br />
      <br /> */}
      {/* <label>
        {t("national_id")}:
        <input
          type="text"
          name="national_id"
          value={formData.national_id}
          onChange={handleChange}
          maxLength={10}
        />
        <input
          type="file"
          name="national_id_image"
          onChange={handleFileChange}
        />
        <br />
      </label> */}
      <br />
      <TextField
        type="number"
        name="national_id"
        label={t("national_id")}
        value={formData.national_id}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        required
      />
      <Input type="file" name="national_id_image" onChange={handleFileChange} />
    </div>
  );
};

export default Step6;
