import React from "react";
import ContactForm from "./ContactForm";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <section className="contact-wrapper">
      <div className="faq-image">
        <div className="image-overlay"></div>
        <div className="faq-section-one">
          <h1>{t("get_in_touch")}</h1>
          <p>{t("contact_text")}</p>
        </div>
      </div>
      <ContactForm />
      <Footer />
    </section>
  );
};

export default Contact;